<template>
  <div class="common_sub_container">
    <section>
      <div class="address_book_primary_view">
        <h3 class="bulk">Upload Bulk Booking File</h3>
            <div class="bulk_booking_courier_selecter" style="display: flex;">
              <div class="label_and_element_wrapper" style="display: flex;margin: 0 auto;">
               
                
                <div style="padding-right: 4rem;">
                  <select v-model="good" class="select_dropdown">
                    <option value disabled>Select Type of Goods</option>
                    <option v-for="(good,i) in goods" :key="'goods'+i" :value="good.id">{{good.type}}</option>
                  </select>
                </div>
              <div>
                  <select v-model="servicetype" class="select_dropdown">
                    <option value disabled>Select Service Type</option>
                    <option v-for="(type,i) in servicetypes" :key="'types'+i" :value="type.id">{{type.service_type}}</option>
                  </select>
                </div>
             

              </div>
            </div>  

        <p>Choose the Bulk Encoding upload file. Please remember only .csv files are accepted and it should be less than 2mb in size.</p>
        <p>
          You can download sample .csv format
          <a :href="sample_url">here</a>
        </p>

        <label class="bt_upload_file">
          CHOOSE A FILE
          <input type="file" ref="myFiles" @change="handleFileUpload" v-if="!loading" />
        </label>
      </div>
    </section>
    <section></section>
    <div class="black-overlay" v-show="showpopSelectDestination">
      <div class="box-modal smallPops">
        <div class="body">
          <popErrorList
            :errors="showpopSelectDestination"
            v-if="showpopSelectDestination"
            @closeModal="closeConsignment"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import popErrorList from "./popups/popErrorList.vue";
export default {
  name: "BulkBookingUser",
  components: {
    popErrorList
  },
  created(){
    this.$emit("breadCrumbData",["Bulk Booking"]);
    this.sample_url = process.env.VUE_APP_API_URL + "/api/booking/user/bulkbooking/sample?token="+localStorage.token;
    this.fetchPackageType();
    this.fetchCarrierServiceType();
  },
  data() {
    return {
      showpopSelectDestination:"",
      files: "",
      sample_url:"",
      error_data: [],
      loading:false,
      //carriers:[],
      goods:[],
      servicetypes:[],
      good:"",
      servicetype:"",
    };
  },
  methods: {
    // fetchDeliveryProvider(){
    //   this.axios.get("/api/provider/")
    //   .then(response => {
    //       this.carriers = response.data.providers;
    //   })
    //   .catch(error =>{
    //       this.toast.error();
    //   });
    // },

     fetchPackageType(){
      this.axios.get("/api/goods/quotebook")
      .then(response => {
        
        this.goods = response.data.goods; 
      })
      .catch(error => {
        console.log(error);
      });
    },

     fetchCarrierServiceType(){
      this.axios.get("/api/common/servicetype")
      .then(response => {
          this.servicetypes = response.data.servicetype; 
      })
      .catch(error =>{
          this.toast.error();
      });
    },

    handleFileUpload() {
      this.error_data = [];
      this.files = this.$refs.myFiles.files[0];
      let formData = new FormData();
      formData.append("sample_file", this.files);
      formData.append("good", this.good);
      formData.append("servicetype", this.servicetype);
      this.axios
        .post("/api/booking/bulkbooking", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          console.log(response);
          this.toast.success(response.data.msg);
          // this.$router.go(this.$router.currentRoute);
        })
        .catch(error => {
          // this.$router.go(this.$router.currentRoute);
          if (error.response && error.response.data && error.response.data.errors) {
            this.showpopSelectDestination = error.response.data.errors;
          }
        });
    },
    closeConsignment(){
      this.showpopSelectDestination = '';
      this.$router.go(this.$router.currentRoute);
    },
  }
};
</script>

<style  lang="scss"  >
.bulk_booking_courier_selecter{
  width:500px; height:auto; padding:20px 0; margin:auto;
  
}
.address_book_primary_view h3.bulk
{
  padding-bottom: 0;
}

.label_and_element_wrapper select
{
  background-position: right 5px top 50% !important;
 
}
.select_dropdown
{
width:120% !important;

}

</style>